import React, { useState } from 'react';

const Button: React.FC = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleClick = () => {
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 2000);
  };

  return (
    <div className="relative inline-block">
      <button
        data-tip
        data-for="tooltip"
        onClick={handleClick}
        className="bg-white text-black font-semibold py-2 px-4 rounded-md"
      >
        ¡Empecemos!
      </button>
    </div>
  );
};

export default Button;