import React from 'react';
import ColorChip from './components/ColorChip';
import ShowText from './components/ShowText';
import ProfileCard from './components/ProfileCard';
import Button from './components/Button';
import SubscribeContainer from './components/SubscribeContainer';

import logo from './assets/logo_prop.png';
import designExamples from './assets/design_example.png';
import bCard1 from './assets/heart.png';
import bCard2 from './assets/bcard2.png';
import bCard3 from './assets/ray.png';
import bCard4 from './assets/bcard4.png';
import bCard5 from './assets/contract.png';
import './App.css';

function App() {
  return (
    <div className="background">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="content-container">
        <div className="foreground-container">
          <div className="content">
            <ShowText color="black" size="3.5" family="Raleway" text="Una agencia de diseño" />
            <ShowText color="black" size="3.5" family="Raleway" text="hecha a tu medida" />
            <ShowText color="black" size="2" family="Montserrat" text="Diseños ilimitados para negocios y empresas" />
          </div>
          <div className="content">
            <img src={designExamples} alt="DesignExamples" />
          </div>
        </div>

        <br/><br/>

        <ShowText color="black" size="3" family="Raleway" text="Lo que necesites & más" />
        <div className="flex flex-wrap gap-1 p-6">
          <ColorChip color="bg-blue-500" text="Landing pages" />
          <ColorChip color="bg-green-500" text="Presentaciones" />
          <ColorChip color="bg-red-500" text="Diagramas" />
          <ColorChip color="bg-yellow-500" text="Webflow" />
          <ColorChip color="bg-purple-500" text="Íconos" />
          <ColorChip color="bg-pink-500" text="App Móviles" />
        </div>
        <div className="flex flex-wrap gap-1">
          <ColorChip color="bg-yellow-500" text="Reportes" />
          <ColorChip color="bg-pink-500" text="Branding" />
          <ColorChip color="bg-purple-500" text="Logos" />
          <ColorChip color="bg-green-500" text="Blogs" />
          <ColorChip color="bg-blue-500" text="Y más" />
        </div>

        <br/><br/>
        <br/><br/>

        <div className="info-container">
          <div className="wrapper">
            <SubscribeContainer backgroundColor="#f0f0f0">
              <ShowText color="black" size="3.5" family="Raleway" text="Membresía" />
              <ShowText color="black" size="2" family="Montserrat" text="¿Qué incluye?" />
              <ShowText color="black" size="1" family="Montserrat" text="• Suscríbete y solicita tantos diseños como quieras." />
              <ShowText color="black" size="1" family="Montserrat" text="• Una solicitud a la vez." />
              <ShowText color="black" size="1" family="Montserrat" text="• Recibe tus diseños en un promedio de dos días hábiles." />
              <ShowText color="black" size="1" family="Montserrat" text="• Revisamos los diseños hasta que estés 100% satisfecho." />
              <ShowText color="black" size="1" family="Montserrat" text="• Puedes pausar o cancelar en cualquier momento." />
            </SubscribeContainer>
            <SubscribeContainer backgroundColor="#000000">
              <ShowText color="white" size="3.5" family="Raleway" text="¡Suscríbete!" />
              <ShowText color="white" size="2" family="Montserrat" text="Tarifa mensual" />
              <ShowText color="white" size="3" family="Montserrat" text="$299.990 CLP/m" />
              <br/>
              <Button />
            </SubscribeContainer>
          </div>
        </div>

        <br/><br/>
        <br/><br/>

        <ShowText color="black" size="3" family="Raleway" text="Beneficios de la Membresía" />
        <div className="flex flex-wrap gap-20 p-6">
          <ProfileCard 
            imageSrc={bCard1}
            title="Queremos que te guste"
            description="Garantía de satisfacción incondicional. Diseñamos hasta que quedes satisfecho."
          />
          <ProfileCard 
            imageSrc={bCard2}
            title="Calidad asegurada"
            description="Diseño de calidad excepcional siempre que lo necesites, directamente a tu alcance."
          />
          <ProfileCard 
            imageSrc={bCard3}
            title="Entregas ultra rápidas"
            description="Recibe tus diseños personalizados en solo unos pocos días."
          />
        </div>
        <div className="flex flex-wrap gap-20 p-6">
          <ProfileCard 
            imageSrc={bCard4}
            title="Procesos fáciles"
            description="Sin complicaciones. Gestionamos fácilmente tu cola de diseño de forma transparente."
          />
          <ProfileCard 
            imageSrc={bCard5}
            title="Único y todo tuyo"
            description="Lo que diseñamos es especialmente pensado para ti. Es 100% tuyo."
          />
        </div>

          <br/><br/>
          <img src={logo} alt="Logo" className="max-w-sm" />
        <br/><br/><br/>
      </div>
    </div>
  );
}

export default App;
