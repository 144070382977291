import React from 'react';

interface ProfileCardProps {
  imageSrc: string;
  title: string;
  description: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ imageSrc, title, description }) => {
  return (
    <div className="flex flex-col items-center p-4 rounded-lg max-w-sm mx-auto">
      <img src={imageSrc} alt={title} className="rounded-full w-32 h-32 object-cover mb-4" />
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default ProfileCard;