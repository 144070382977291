import React from 'react';

interface ColorChipProps {
  color: string;
  text: string;
}

const ColorChip: React.FC<ColorChipProps> = ({ color, text }) => {
  return (
    <div className={`w-32 h-12 rounded-full flex items-center justify-center ${color}`}>
      <span className="text-white">{text}</span>
    </div>
  );
};

export default ColorChip;