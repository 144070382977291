import React from 'react';

interface SubscribeContainerProps {
  backgroundColor: string;
  children: React.ReactNode;
}

const SubscribeContainer: React.FC<SubscribeContainerProps> = ({ backgroundColor, children }) => {
  const containerStyle: React.CSSProperties = {
    flex: '1 1 300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    boxSizing: 'border-box',
    backgroundColor,
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    minWidth: '250px',
  };

  return <div style={containerStyle}>{children}</div>;
};

export default SubscribeContainer;